<template>
  <div id="FormModal" v-show="dialog">
    <div class="modal">
      <div class="form_header">
        <h4>訂購資料</h4>
        <button @click="dialog = false" class="close_btn">
          <span class="material-icons-outlined"> close </span>
        </button>
      </div>
      <div class="form_body">
        <div class="input_group" v-for="item in form_data" :key="item.id">
          <p>
            {{ item.name }}<span>{{ item.info }}</span>
          </p>
          <template v-if="item.type != 'date'">
            <input
              :value="GetValue(item.name)"
              @input="UpdateValue(item.name, $event.target.value)"
              type="text"
              :placeholder="item.placeholder"
              :name="item.name"
            />
          </template>
          <template v-else>
            <input
              :value="GetValue(item.name)"
              @input="UpdateValue(item.name, $event.target.value)"
              type="date"
              :placeholder="item.placeholder"
              :name="item.name"
            />
          </template>
          <p class="error">{{ GetError(item.name) }}</p>
        </div>
      </div>
      <div class="form_footer">
        <button @click="Validate" class="next_btn">產生訂購單</button>
      </div>
    </div>
    <div class="bg_cover"></div>
  </div>
</template>

<script>
export default {
  name: "FormModal",
  props: {
    product_data: {
      require: true
    },
    value: {
      require: true
    }
  },
  data() {
    return {
      dialog: false,
      errors: []
    }
  },
  methods: {
    Open() {
      this.dialog = true
    },
    GetValue(key) {
      return this.value.filter(item => item.name == key)[0].value
    },
    UpdateValue(key, val) {
      let tmp_value = JSON.parse(JSON.stringify(this.value))
      tmp_value.forEach(item => {
        item.name == key ? item.value = val : ""
      })
      this.$emit("input", tmp_value)
    },
    Validate() {
      this.errors = []
      let vm = this
      this.value.forEach(item => {
        if (item.require) {
          if (item.value == "" || item.value.length <= 0) {
            vm.errors.push(
              {
                type: item.name,
                content: "請勿留空"
              }
            )
          }
        }
      })
      if (this.errors.length <= 0) {
        this.dialog = false
        this.$emit("print")
      }
    },
    GetError(key) {
      let error = this.errors.filter(item => item.type == key)
      return error.length > 0 ? error[0].content : ""
    }
  },
  computed: {
    form_data() {
      return this.product_data == null ? [] : this.product_data.form_data
    }
  },
  mounted() {
    let form = document.querySelector("#FormModal")
    form.style.height = `${window.innerHeight}px`
    const el = document.querySelector('#FormModal');
    [
      'touchmove',
      'mousewheel',
      'wheel',
    ].forEach((eventType) => {
      el.addEventListener(eventType, (e) => e.stopPropagation());
    });
  }
}
</script>