<template>
  <div class="option_drawer pc">
    <div class="header">
      <p>客製化選色<span v-if="name.indexOf('-檔車') != -1">-檔車專用</span></p>
      <h3>{{ name | name }}</h3>
    </div>
    <ol>
      <li v-for="item in active_list" :key="item.option_id">
        <p>
          {{ item.code ? item.code + " " : "" }}{{ item.name
          }}<span v-if="item.warning">{{ item.warning }}</span>
        </p>
        <div class="option_list">
          <template v-for="option in item.options">
            <button
              class="option"
              @click="UpdateUserData(item.name, option.name)"
              :key="option.id"
            >
              <span
                :class="{ active: GetUserValue(item.name) == option.name }"
                :style="`background-color:${option.color}`"
              ></span>
              <p>{{ option.name }}</p>
            </button>
            <button
              v-if="option.divider"
              class="divider"
              :key="option.id"
            ></button>
          </template>
        </div>
      </li>
    </ol>
    <div class="option_footer">
      <button @click="ChangeOptionList(-1)" class="prev_btn">上一步</button>
      <button
        v-if="this.active_option * 2 - 1 < this.option_list.length - 1"
        @click="ChangeOptionList(1)"
        class="next_btn"
      >
        下一步
      </button>
      <button v-else @click="Print" class="next_btn">產生訂單</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "OptionDrawerPc",
  props: {
    name: {
      require: true,
    },
    option_list: {
      require: true,
    },
    user_data: {
      require: true,
    },
  },
  data() {
    return {
      active_option: 1,
    };
  },
  computed: {
    active_list() {
      let option_list = [];
      if (this.option_list[this.active_option * 2 - 2]) {
        option_list.push(this.option_list[this.active_option * 2 - 2]);
      }
      if (this.option_list[this.active_option * 2 - 1]) {
        option_list.push(this.option_list[this.active_option * 2 - 1]);
      }
      return option_list;
    },
  },
  methods: {
    GetUserValue(key) {
      return this.user_data.filter((item) => item.name == key)[0].value;
    },
    UpdateUserData(key, value) {
      this.$emit("update-data", { key: key, value: value });
    },
    ChangeOptionList(val) {
      if (val == -1) {
        this.active_option > 1 ? (this.active_option -= 1) : "";
      } else {
        this.active_option * 2 - 1 < this.option_list.length - 1
          ? (this.active_option += 1)
          : "";
      }
    },
    Print() {
      this.$emit("print");
    },
  },
  filters: {
    name(val) {
      return val.indexOf("-檔車") != -1 ? val.replace("-檔車", "") : val;
    },
  },
  mounted() {},
};
</script>