<template>
  <div class="option_drawer mb">
    <div class="header_box">
      <div class="header">
        <p>
          客製化選色<span v-if="name.indexOf('-檔車') != -1">-檔車專用</span>
        </p>
        <h3>{{ name | name }}</h3>
      </div>
      <button @click="Print">
        <!-- <span class="material-icons-outlined"> ios_share </span> -->
        產生訂單
      </button>
    </div>
    <div class="option_box">
      <div class="header">
        <button @click="ChangeOptionList(-1)">
          <span class="material-icons-outlined"> chevron_left </span>
        </button>
        <p>
          {{
            option_list[active_option - 1].code
              ? option_list[active_option - 1].code + " "
              : ""
          }}{{ option_list[active_option - 1].name }}
          <span v-if="option_list[active_option - 1].warning">{{
            option_list[active_option - 1].warning
          }}</span>
        </p>
        <button @click="ChangeOptionList(1)">
          <span class="material-icons-outlined"> chevron_right </span>
        </button>
      </div>
      <div class="option_list">
        <ol>
          <template v-for="item in option_list[active_option - 1].options">
            <li :key="item.id">
              <button
                @click="
                  UpdateUserData(option_list[active_option - 1].name, item.name)
                "
              >
                <span
                  :class="{
                    active:
                      GetUserValue(option_list[active_option - 1].name) ==
                      item.name,
                  }"
                  :style="`background-color:${item.color}`"
                ></span>
                <p>{{ item.name }}</p>
              </button>
            </li>
            <li v-if="item.divider" class="divider" :key="item.id"></li>
          </template>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OptionDrawerMb",
  props: {
    name: {
      require: true,
    },
    option_list: {
      require: true,
    },
    user_data: {
      require: true,
    },
  },
  data() {
    return {
      active_option: 1,
    };
  },
  methods: {
    GetUserValue(key) {
      return this.user_data.filter((item) => item.name == key)[0].value;
    },
    UpdateUserData(key, value) {
      this.$emit("update-data", { key: key, value: value });
    },
    ChangeOptionList(val) {
      if (val == -1) {
        this.active_option > 1 ? (this.active_option -= 1) : "";
      } else {
        this.active_option < this.option_list.length
          ? (this.active_option += 1)
          : "";
      }
    },
    Print() {
      this.$emit("print");
    },
  },
  filters: {
    name(val) {
      return val.indexOf("-檔車") != -1 ? val.replace("-檔車", "") : val;
    },
  },
  mounted() {},
};
</script>