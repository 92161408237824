<template>
  <div id="PrintOrder">
    <div class="test_print_area" id="mycanvas" ref="mycanvas">
      <template v-if="user_data != null">
        <template v-for="item in user_info">
          <div
            v-if="item.name != '訂購日期'"
            class="text_option"
            :key="item.id"
            :style="`
            top: ${GetFormPos(item.name)[0]}px; 
            left: ${GetFormPos(item.name)[1]}px;
            transform: rotate(${GetFormPos(item.name)[2]}deg);`"
          >
            {{ item.value }}
          </div>
        </template>
        <template v-if="user_info_time != ''">
          <div
            class="text_option"
            :style="`
            top: ${946}px; 
            left: ${590}px;
            transform: rotate(0deg);`"
          >
            {{ user_info_time.slice(0, 4) }}
          </div>
          <div
            class="text_option"
            :style="`
            top: ${946}px; 
            left: ${640}px;
            transform: rotate(0deg);`"
          >
            {{ user_info_time.slice(5, 7) }}
          </div>
          <div
            class="text_option"
            :style="`
            top: ${946}px; 
            left: ${680}px;
            transform: rotate(0deg);`"
          >
            {{ user_info_time.slice(8, 10) }}
          </div>
        </template>
        <div
          class="option"
          v-for="item in user_data"
          :key="item.id"
          :style="`width: ${GetOptionPos(item.name, item.value)[0][0]}px;
              height:  ${GetOptionPos(item.name, item.value)[0][0]}px;
              left:  ${GetOptionPos(item.name, item.value)[1][0]}px;
              top:  ${GetOptionPos(item.name, item.value)[1][1]}px;
              transform: rotate(${
                GetOptionPos(item.name, item.value)[0][1]
              }deg);
              background-color: #000;`"
        >
          <img src="/img/outline_done_black_24dp.png" />
        </div>
        <img
          :src="`/img/customize/${
            product_data.name
          }/pdf.jpg?time=${new Date().getTime()}`"
        />
      </template>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
export default {
  name: "CustomizePrintOrder",
  props: {
    user_data: {
      require: true,
    },
    product_data: {
      require: true,
    },
    user_info: {
      require: true,
    },
  },
  computed: {
    user_info_time() {
      let time = this.user_info.filter((item) => item.name == "訂購日期");
      time = time.length > 0 && time.value != "" ? time[0].value : "";
      return time;
    },
  },
  methods: {
    Print() {
      let vm = this;
      this.$store.commit("SetPageLoading", 1);
      window.pageYoffset = 0;
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      var node = document.getElementById("mycanvas");
      var width = node.offsetWidth;
      var height = node.offsetHeight;
      var scale = 2;
      html2canvas(node, {
        width: width,
        heigth: height,
        backgroundColor: "#ffffff",
        dpi: 300,
        scale: scale,
        X: 0,
        Y: 0,
        scrollX: -3,
        scrollY: -10,
        useCORS: true,
        allowTaint: true,
      }).then((canvas) => {
        var url = canvas.toDataURL();
        vm.$store.commit("SetImageModal", {
          status: true,
          images: [url],
          active_index: 0,
        });
        vm.$store.commit("SetDialog", {
          action: true,
          content: "長按圖片或右鍵儲存，方便轉傳下單！！",
        });

        this.$store.commit("SetPageLoading", -1);
      });
    },
    GetOptionPos(key, val) {
      let option_type = this.product_data.option_type.filter(
        (item) => item.name == key
      )[0];
      let size = option_type.size;
      let rotate = option_type.rotate;
      let pos = [
        option_type.options.filter((item) => item.name == val)[0].left,
        option_type.options.filter((item) => item.name == val)[0].top,
      ];
      return [[size, rotate], pos];
    },
    GetFormPos(key) {
      let form_data = this.product_data.form_data.filter(
        (item) => item.name == key
      )[0];
      return [form_data.top, form_data.left, form_data.rotate];
    },
  },
};
</script>

<style lang="scss">
.test_print_area {
  position: relative;
  width: 100%;
  height: 1100px;

  .option {
    position: absolute;
    z-index: 999999;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: 8px;
      transform: scale(0.9);
    }

    img {
      width: 10px;
      height: 10px;
      display: block;
    }
  }

  .text_option {
    position: absolute;
    display: inline-block;
    text-align: left;
    transform-origin: left top;
    z-index: 999999;
    color: #000;
    line-height: 1;
    font-size: 12px;
  }

  img {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.pdf_page {
  width: 100%;
  height: 1100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.pdf_content_box {
  flex: 1;
  width: 100%;
}
.pdf_header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  img {
    width: 100px !important;
    display: block;
  }
  h4 {
    margin: 0;
    font-size: 18px;
  }
}
.pdf_footer {
  width: 100%;
  padding: 0px 30px;
  .dealer_box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .content {
      width: 50%;
      min-height: 200px;
    }
  }
  .info_box {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    .pdf_footer_logo {
      display: block;
      height: 40px;
      margin-right: 20px;
    }
    .pdf_qr_code {
      display: block;
      height: 40px;
      margin-right: 10px;
    }
    .info {
      .info_group {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0 5px 5px;
        transform: scale(0.9) translateY(5px);
        .icon {
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 2px;
          background-color: #666;
          color: #fff;
          margin-right: 5px;
          span {
            font-size: 8px;
            display: block;
          }
        }
        p {
          margin: 0;
          font-size: 8px;
        }
      }
    }
  }
}
.pdf_hr {
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  color: #666;
  hr {
    flex: 1;
    margin-left: 10px;
    border: none;
    border-top: 1px solid #666;
  }
}
.pdf_option_box {
  width: 100%;
  display: flex;
  margin: 0 auto;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 30px;

  .option {
    width: 33.3333%;
    text-align: left;
    margin-bottom: 30px;
    h4 {
      font-size: 16px;
      font-weight: 500;
      margin: 0;
    }
    p {
      margin: 5px 0 0;
      font-size: 14px;
      color: #666;
    }
  }
}
</style>
